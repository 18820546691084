'use client';
import React, { Fragment, useState } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { FormBlockSchema } from '@/sanity/schemas/blocks/essential/formBlock';
import { TreatmentSchema } from '@/sanity/schemas/documents/treatment';
import { SlugValue } from '@sanity/types';
import Form from '@/components/Form';

interface Option {
  icon: any;
  title: string;
}

interface Treatment {
  title: string;
  slug: string;
}

type ValidationOptions = {
  maxLength?: number;
  pattern?: RegExp;
  required?: string;
};

type FormFieldBase = {
  name: string;
  label?: string;
  type: string;
  validation?: ValidationOptions;
};

type SelectField = {
  name: string;
  label: string;
  type: 'select';
  options: { label: string; value: SlugValue }[];
};

type FormField = FormFieldBase | SelectField;

interface ResponseData {
  success: boolean;
  message: string;
}

let initialFormFields: FormFieldBase[] = [
  {
    type: 'text',
    name: 'firstName',
    label: 'First name',
    validation: {
      maxLength: 80,
      required: 'Please enter your name.'
    }
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Surname',
    validation: {
      maxLength: 80
    }
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email address',
    validation: {
      required: 'Please enter your email address.',
      pattern: /^\S+@\S+$/i
    }
  },
  {
    type: 'tel',
    name: 'phoneNumber',
    label: 'Phone Number',
    validation: {
      required: 'Please enter your phone number.',
      maxLength: 12
    }
  },
  {
    type: 'textarea',
    name: 'message',
    label: 'Message',
    validation: {}
  },
  {
    type: 'honeypot',
    name: 'fullname'
  }
];

const FormBlock = ({ _key, name, treatments }: FormBlockSchema) => {
  const pathname = usePathname();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const legal = <Fragment>
    This data will only be used to reply to your request for information. This site is protected and
    our <Link className={'underline'} href="/privacy-policy">privacy policy</Link> and&nbsp;
    <Link className={'underline'} href="/terms-and-conditions">terms of service</Link> apply.
  </Fragment>;

  const onSubmit = async data => {
    try {
      const formBody = {
        ...data,
        submittedFrom: pathname,
        formName: 'BookForm',
        submitted: new Date()
      };
      if (data && data.fullname !== null || data.fullname !== '') {
        await fetch(`/api/contact`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify(formBody)
        }).then(response => response.json() as Promise<ResponseData>)
          .then(data => {
            if (data.success) {
              setShowSuccessMessage(true);
              setError(false);
            } else {
              setErrorMessage(data.message);
              setError(true);
            }
          });
      }
    } catch (e) {
      setError(true);
    }
  };

  const treatmentOptions = treatments.map((treatment: TreatmentSchema) => ({
    label: treatment.title,
    value: treatment.slug
  }));

  const formFields: FormField[] = [
    ...initialFormFields,
    {
      type: 'select',
      name: 'treatment',
      label: 'Treatment',
      options: treatmentOptions
    }
  ];

  return <section key={`form-${_key}`} className={`w-full`}>
    <div className="container px-5 py-24 mx-auto flex">
      <div
        className="rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative">
        <Form
          formFields={formFields}
          defaultValues={{}}
          onSubmit={onSubmit}
          showSuccessMessage={showSuccessMessage}
          error={error}
          submitSuccessfulMessage={'Thanks for your enquiry, I will be in touch ASAP.'}
        />
      </div>
    </div>
  </section>;
};
export default FormBlock;
