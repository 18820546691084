'use client';
import React, { Fragment, useRef } from 'react';
import { Input } from '@/components/Form/field/Input';
import { TextArea } from '@/components/Form/field/TextArea';
import { DatePicker } from '@/components/Form/field/DatePicker';
import { useForm } from 'react-hook-form';
import { Select } from '@/components/Form/field/Select';
import { Turnstile } from '@marsidev/react-turnstile';

/**
 * @param {Array} formFields
 * @param {object} defaultValues
 * @param {Function} onSubmit
 * @param {boolean} error
 * @param {boolean} showSuccessMessage
 * @param {string} submitSuccessfulMessage
 * @returns {JSX.Element}
 * @constructor
 */
export default function Form({
                               formFields = [],
                               defaultValues = {},
                               onSubmit,
                               error = false,
                               showSuccessMessage = false,
                               submitSuccessfulMessage = 'Thanks for your enquiry, I will be in touch ASAP.'
                             }) {
  const formRef = useRef(null);
  const { register, handleSubmit, formState: { errors, isSubmitting, isSubmitSuccessful, isLoading } } = useForm({
    ...defaultValues
  });

  const handleCaptcha = async (data) => {
    const formData = new FormData();
    const token = formData.get('cf-turnstile-response');
    const res = await fetch('/api/captcha', {
      'method': 'POST',
      body: JSON.stringify({ token }),
      headers: {
        'content-type': 'application/json'
      }
    });
    const turnstileResponse: any = await res.json();
    if (turnstileResponse && turnstileResponse.success) {
      onSubmit(data);
    }
  };

  return (
    <Fragment>
      {error ? (
        <div className="bg-red-50 p-4 rounded">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" aria-hidden="true" className="h-5 w-5">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm">
                There was an issue submitting your enquiry. Please try again later.
              </p>
            </div>
          </div>
        </div>
      ) : null}
      {showSuccessMessage ? (
        <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
             role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                   stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm">{submitSuccessfulMessage}</p>
            </div>
          </div>
        </div>
      ) : (
        <form ref={formRef} onSubmit={handleSubmit(handleCaptcha)}>
          <div className="flex flex-col space-y-4">
            {formFields.map((field) => {
              switch (field.type) {
                case 'text':
                  return (
                    <Input
                      register={register}
                      validation={field.validation}
                      name={field.name}
                      label={field.label}
                      errors={errors}
                    />
                  );
                case 'select':
                  return (
                    <Select
                      register={register}
                      validation={field.validation}
                      name={field.name}
                      label={field.label}
                      options={field.options}
                    />
                  );
                case 'textarea':
                  return (
                    <TextArea
                      register={register}
                      validation={field.validation}
                      name={field.name}
                      label={field.label}
                    />
                  );
                case 'email':
                  return (
                    <Input
                      register={register}
                      validation={field.validation}
                      type={field.type}
                      name={field.name}
                      label={field.label}
                      errors={errors}
                    />
                  );
                case 'date':
                  return (
                    <DatePicker
                      register={register}
                      validation={field.validation}
                      name={field.name}
                      label={field.label}
                    />
                  );
                case 'honeypot':
                  return <Input
                    register={register}
                    type={'hidden'}
                    name={field.name}
                    label={field.label}
                    errors={errors}
                  />;
                default:
                  return (
                    <Input
                      register={register}
                      validation={field.validation}
                      type={field.type}
                      name={field.name}
                      label={field.label}
                      errors={errors}
                    />
                  );
              }
            })}
            <div className="relative">
              <Turnstile
                siteKey={process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
              />
            </div>
          </div>
          <div>
            <button
              disabled={isSubmitSuccessful || isSubmitting || isLoading}
              type="submit"
              className={`lg:w-1/6 md:w-1/3 sm:w-1/2 w-full text-xl bg-brand-500 hover:bg-brand-700 font-bold text-white px-4 py-2 rounded-lg ${(isSubmitting || isLoading) ? 'bg-gray-100 text-black' : ''}`}
            >
              {(isSubmitting || isLoading) ? 'Loading' : 'Submit'}
            </button>
          </div>
          {/*<p className="mt-4 text-xs text-white text-center">*/}
          {/*  {legal}*/}
          {/*</p>*/}
        </form>
      )}
    </Fragment>
  );
}