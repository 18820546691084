import React from 'react';

export const Input = ({
                        register,
                        name,
                        label,
                        type = 'text',
                        required = false,
                        validation = {},
                        errors
                      }) => {
  return (
    <div className="relative mb-4">
      <label htmlFor={label} className="leading-7 text-sm">{label}
        <input
          {...register(name, validation)}
          type={type}
          id={name}
          name={name}
          className={`input ${errors[name] ? 'border-red-500 bg-red-100' : ''}`}
          required={required}
        />
        {errors[name] && <span role="alert">{errors[name]?.message}</span>}
      </label>
    </div>
  );
};