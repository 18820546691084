import React from "react";

export const Select = ({ register, name, label, validation = {}, options = [] }) => {
  return (
    <div className="relative mb-4">
      <label htmlFor={label} className="leading-7 text-sm">{label}
        <select {...register(name, validation)} name={name} id={name} className={"input"}>
          {options.map((option, key) => {
            return <option key={key} value={option.value}>{option.label}</option>;
          })}
        </select>
      </label>
    </div>
  );
};