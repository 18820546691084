// Body.js
import React from 'react';
import { PortableText, type PortableTextComponents } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { getByReference } from '@/sanity/queries';

const components: PortableTextComponents = {
  marks: {
    internalLink: async ({ value, children }) => {
      const doc = await getByReference(value.reference._ref);
      const { slug = {} } = doc;
      const href = `/${slug.current}`;
      return <a href={href}>{children}</a>;
    },
    link: ({ value, children }) => {
      const { blank, href } = value;
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    code: ({ children }) => <pre>{children}</pre>,
  },
};

export const BodyContent = ({ blocks }: { blocks: PortableTextBlock }) => {
  return <PortableText value={blocks} components={components} />;
};
