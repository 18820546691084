const dateTimeFormat = new Intl.DateTimeFormat('en-GB');

/**
 *
 * @param register
 * @param validation
 * @param label
 * @param name
 * @param required
 * @returns {JSX.Element}
 * @constructor
 */
export const DatePicker = ({ register, validation = {}, label, name, required = false }) => {
  const minDate = new Date();
  const maxDate = minDate.setMonth(minDate.getMonth() + 8);
  return <div className="relative mb-4">
    <label htmlFor={label} className="leading-7 text-sm">{label}
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
             fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
        </svg>
      </div>
      <input
        {...register(name, validation)}
        id={name}
        type="date"
        min={dateTimeFormat.format(minDate)}
        max={dateTimeFormat.format(maxDate)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Select class date"
      />
    </label>
  </div>;
};