'use client';
import { TestimonialsBlockSchema } from '@/sanity/schemas/blocks/essential/testimonialsBlock';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaCarouselType } from 'embla-carousel';
import { useCallback } from 'react';
import { NextButton, PrevButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import Autoplay from 'embla-carousel-autoplay';
import { BodyContent } from '@/components/blocks/BodyContent';
import { PortableTextBlock } from '@portabletext/types';

export default function TestimonialsBlock({
                                            _key,
                                            title,
                                            testimonials = []
                                          }: TestimonialsBlockSchema) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  const onButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const { autoplay } = emblaApi.plugins();
    if (!autoplay) return;
    if (autoplay.options.stopOnInteraction !== false) autoplay.stop();
  }, []);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi, onButtonClick);
  return (
    <section key={_key} className="relative bg-gray-100">
      <div className="flex flex-none flex-shrink-0 relative break-word items-center">
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 z-10" />
        </div>
        <div className="relative z-10 container mx-auto pt-16 lg:pt-32 pb-16 lg:pb-32">
          <div className="transition-all transition-all ease-in-out duration-500 opacity-100 translate-y-0">
            {title && (
              <h3 className={'text-center heading-h3 white-space:pre-line'}>
                {title}
              </h3>
            )}
            <div className="embla pt-4 lg:pt-12 pb-12 lg:pb-24">
              <div className="overflow-hidden" ref={emblaRef}>
                <div className="embla__container">
                  {testimonials.map((testimonial) => {
                    const { _key, name, content } = testimonial;
                    return (
                      <div className="embla__slide" key={_key}>
                        <div className="space-y-4">
                          {name ? (
                            <h4 className={'heading-h4 white-space:pre-line'}>
                              {name}
                            </h4>
                          ) : null}
                          {content ? (
                            <div
                              className="prose prose-h3:heading-h3 prose-h4:heading-h4 prose-h5:heading-h5 prose-p:paragraph xl:prose-p:paragraph-md">
                              <BodyContent
                                blocks={content as PortableTextBlock}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="left-6 flex md:mt-0 items-center absolute bottom-0 transform translate-y-1/2">
                <PrevButton
                  onClick={onPrevButtonClick}
                  disabled={prevBtnDisabled}
                />
                <NextButton
                  onClick={onNextButtonClick}
                  disabled={nextBtnDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
