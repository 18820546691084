export const TextArea = ({ register, validation = {}, name, label, required = false }) => {
  return <div className="relative mb-4">
    <label htmlFor={label} className="leading-7 text-sm">{label}
      <textarea
        {...register(name, validation)}
        id={name}
        name={name}
        className="input"
        required={required}
      />
    </label>
  </div>;
};